<template>
  <a-breadcrumb :routes="breadcrumbList" class="breadcrumb">
    <template slot="itemRender" slot-scope="{route, params, routes}">
      <template v-if="!route.meta.hideInBread">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{route.meta.title}}
        </span>
        <router-link v-else :to="route.path">
          <icon :type="route.meta.icon"/>
          {{route.meta.title}}
        </router-link>
      </template>
    </template>
  </a-breadcrumb>
</template>

<script>
export default {
  name: 'Breadcrumb',
  computed: {
    breadcrumbList () {
      return this.$route.matched
    }
  }
}
</script>

<style lang="less" scoped>
  .breadcrumb{
    margin-bottom: 16px;
  }
</style>
